import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import PageWrapper from "../components/pageWrapper"


import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const LongTermCareFactsMyths = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    
  return (
  <PageLayout>
    <SEO
      title="Long Term Care Facts | The Long Term Care Planning Group"
      ogDescription="There are many long term care facts and myths. Corey Rieck of The Long Term Care Planning Group helps you to understand how LTC actually works."
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h1>
            Long Term Care Facts and Myths
          </h1>
          <p>
            Most of us will face functional limitations or permanent disability as we age. Professional medical care at home or in a nursing home can be expensive. And those costs rise each year. But, there are many misconceptions regarding long term care. Better understanding the facts around the topic will help ensure you make the right choices for your family and financial situation.
          </p>
          <RegisterForWebinarButton />
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/AdobeStock_135904252.jpg"
            alt="Long Term Care Facts and Myths"
          />
        </div>
      }
    />
    <PageWrapper maxWidth color="blue">
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img
            src="/images/AdobeStock_242400048.jpg"
            alt="Long Term Care Facts Government"
          />
          <h2>MYTH: <a href="/government-does-not-pay-for-long-term-care/">THE GOVERNMENT WILL TAKE CARE OF ME</a></h2>
          <p><strong>Fact:</strong> Government programs have experienced a tightening of the finances when it comes to covering custodial expenses related to long term care unless you meet certain minimum income, asset and physical levels as set by the state in which you reside. You will also lose control of the amount of money spent on your care and your access to that care.</p>
        </div>
      }
      textRight={
        <div>
          <h2>MYTH: I CAN SELF FUND THE COSTS OF LONG TERM CARE</h2>
          <p><strong>Fact:</strong> <a href="/genworth-long-term-care-costs/">Long Term Care costs</a> can exceed $75,000 annually, or $6,250 per month. These costs can rise as much as 5% annually, as shown in the <a href="https://www.metlife.com/about-us/newsroom/2012/april/metlife-study-finds-contrary-to-predictions--boomers-are-retirin/" target="_blank">November 2012 Market Survey of Long Term Care Costs</a> taken by the MetLife Mature Market Institute.</p>

          <h2>MYTH: MY FAMILY WILL TAKE CARE OF ME</h2>
          <p><strong>Fact:</strong> Multiple polls have shown that families in fact do not want to deliver care to other family members. However, unfortunately if no formal planning is completed for Long Term Care the family is forced to deliver care. Geography, the care learning curve and <a href="/impact-of-caregiving/">difficult relationships within the family</a> must be considered. Even willing family members may not be able to become caregivers.</p>
          <ScheduleVisitButton />
        </div>
      }
    />
    </PageWrapper>
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h2>MYTH: I WILL NEVER GO TO A NURSING HOME</h2>
          <p><strong>Fact:</strong> You may be right. You may never go to a nursing home in order to receive services related to long term care. You may receive <a href="/long-term-health-care/">care in your home, adult day care or in an assisted living facility</a>. Long Term Care describes a level of assistance, not a location. If you need Long Term Care, you may be able to stay in your home if you have Long Term Care Insurance.</p>

          <h2>MYTH: IT IS NURSING HOME INSURANCE</h2>
          <p><strong>Fact:</strong> Long Term Care is a continuum of care, housing and services that you may need as a result of living a long life. The Nursing home is only one of the venues in which you can receive care.</p>
          <ScheduleVisitButton />
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/AdobeStock_316122232.jpg"
            alt="Long Term Care Facts About Nursing Homes"
          />
        </div>
      }
    />
    <TextImageBlock
      image={
        <img
          src="/images/AdobeStock_262943311.jpg"
          alt="Long Term Care Facts About Coverage"
        className="right-point"
        />
      }
      imageGrow
      textPadded
      text={
        <div>
          <h2>MYTH: I’M ALREADY COVERED FOR LONG TERM CARE</h2>
          <p><strong>Fact:</strong> Unless you have implemented a long term care policy, you may not be covered the way that you think you are. Many think that their health Insurance and disability income will help; but that type of coverage serves very different functions. Your health insurance largely uses skilled care to help you get better by delivering occupational therapy, physical therapy or tube feeding. These examples of skilled care are delivered by trained professionals like Physical Therapists, Occupational Therapists and Medical Doctors. Your disability income will generally protect a portion of your income while you are working for a finite period of time, and the underwriting has both a physical and financial component to it. If you have previously implemented a long term care plan, it may be time to review it to make sure there is no unnecessary exposure.</p>
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img
            src="/images/AdobeStock_250496474.jpg"
            alt="Long Term Care Facts - Chances"
          />
        </div>
      }
      textRight={
        <div>
          <h2>MYTH: IT WILL NEVER HAPPEN TO ME</h2>
          <p><strong>Fact:</strong> You may be right. It may never happen to you. However, should the long term care issue and its associated challenges present itself to you and your family, the consequences to your family’s emotional and physical well-being can be significant; and so can the consequences to your financial plan and your income.</p>
      
          <h2>MYTH: I’LL TAKE CARE OF MOM OR DAD</h2>
          <p><strong>Fact:</strong> We know you will, as that is what families do. Long Term Care Planning may help you do it longer and better. Ultimately, the effective design and implementation of a Long Term Care Plan will afford the family the opportunity to have a formal plan of care put in place by subject matter experts with monitoring components that will keep family members in the loop. The care will be delivered by people who are well-versed in how to assist care recipients with physical impairment or cognitive related issues.</p>
        </div>
      }
    />
    <TextImageBlock
      image={
        <img
          src="/images/AdobeStock_64709720.jpg"
          alt="Long Term Care Facts and Myths About Cost"
        className="left-point"
        />
      }
      imageGrow
      imageRight
      textPadded
      text={
        <div>
          <h2>MYTH: LONG TERM CARE IS TOO EXPENSIVE</h2>
          <p><strong>Fact:</strong> It is important to keep costs in perspective and make meaningful comparisons. The average cost of care nationally according to the November 2012 Market Survey of Long Term Care Costs can approach $75,000 annually, or $6,250 per month. You can create a long term care policy with your long term care advisor (assuming you can health qualify) that can be a little as several hundred dollars a month as opposed to several thousand a month for care. Once you successfully navigate underwriting, you can tailor the plan to your budget and needs. Ultimately, pending successful underwriting, you can decide with your long term care advisor how much risk to give to the carrier and how much risk to take on yourself.</p>
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h2>MYTH: I CAN SAVE FOR MY LONG TERM CARE EXPENSES</h2>
          <p><strong>Fact:</strong> The average annual cost for long term care related expenses are approximately $75,000 per year and rise as much as 5% annually. In order to adequately finance this now, you may need to have as much as $2,000,000 in savings for long term care only. If you did have this $ 2,000,000 savings plan for long term care, you would need to potentially allow money for taxes and other expenditures, and a $2,000,000 long term care nest egg would generate approximately $80,000 annually, assuming a 4% return. Without this level of dedicated savings, you may be left without care or be forced to rely upon family, friends or the government to care for you.</p>

          <h2>MYTH: LONG TERM CARE IS AN “OLD PERSON’S” ISSUE</h2>
          <p><strong>Fact:</strong> You may face Long Term Care concerns and the associated challenges at any time, and at any age. While many of the care recipients who utilize long term care benefits are elderly, people at younger ages can also utilize the benefits as a result of a prolonged illness, disability or accident.</p>
          <ScheduleVisitButton />
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/AdobeStock_318153694.jpg"
            alt="Long Term Care Facts and Myths Considerations"
          />
          <h2>MYTH: I AM GOING TO WAIT TO CONSIDER LONG TERM CARE</h2>
          <p><strong>Fact:</strong> There are three considerations on this matter:</p>

          <h3>Your insurability</h3>
          <p><strong>Market Forces:</strong> (what the products are that are currently available)
          Cost of Waiting: The longer you wait, generally the more premium you will pay.
          Premiums generally increase 3% to 4% for each year in age. For example, a 41 year
          old may pay 3% to 4% more in premium than a 40 year old for the exact same LTC
          plan design. Many consider delaying the purchase of Long Term Care for a period
          of years and because of this fact may actually end up paying more in premiums
          given their life expectancy.</p>
        </div>
      }
    />
  </PageLayout>
  )
}

export default LongTermCareFactsMyths
